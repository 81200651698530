import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment-timezone';
import { NavLink, useLocation } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import { DATE_FORMAT } from 'globals/global.constants';
import { FACILITY_TYPES, OnboardingTab } from 'globals/enums';
import { theme } from 'config/theme.config';
import { getSeniorResidentBaseUrl } from 'globals/global.functions';
import { getDateDifference } from 'globals/date.functions';
var SeniorShortDetail = function (_a) {
    var _b;
    var data = _a.data, isResident = _a.isResident;
    var location = useLocation();
    var getCurrentAge = function (value) {
        var ageDifMs = Date.now() - new Date(value).getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    var getSeniorOnboardingURL = getSeniorResidentBaseUrl();
    var list = [
        {
            label: 'Member Since',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: data.created_date
                ? moment(data.created_date).format(DATE_FORMAT)
                : '',
            isVisible: true,
        },
        {
            label: 'Member ID',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: (_b = data === null || data === void 0 ? void 0 : data.member_id) !== null && _b !== void 0 ? _b : 'NA',
            isVisible: true,
        },
        {
            label: 'DOB/Age',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: data.dob
                ? "".concat(moment.utc(data.dob).format('MM-DD-YYYY'), "/").concat(getCurrentAge(data.dob), " yrs")
                : '',
            isVisible: true,
        },
        {
            label: 'Community',
            route: '',
            value: data.facility,
            isVisible: data.isResident || false,
        },
        {
            label: 'Length of Stay',
            route: '',
            value: getDateDifference(moment(), data === null || data === void 0 ? void 0 : data.created_date),
            isVisible: (isResident &&
                data.isResident &&
                data.facility_type === FACILITY_TYPES.INDEPENDENT_LIVING) ||
                false,
        },
    ];
    return (_jsx(Grid, { container: true, children: list.map(function (item) {
            return item.isVisible && (_jsxs(Grid, { container: true, style: { justifyContent: 'space-between', padding: '1.5px 0' }, children: [_jsx(Grid, { item: true, style: { flex: 2.2 }, children: _jsxs(Typography, { style: { fontWeight: 'bold' }, variant: 'body1', children: [item.label, ":\u00A0"] }, item.label) }), _jsx(Grid, { item: true, style: { flex: 2.5 }, children: _jsx(Typography, { style: { wordBreak: 'break-all' }, variant: 'body1', children: item.value ? (_jsx(NavLink, { to: item.route || location.pathname, style: {
                                    color: theme.palette.common.black,
                                }, children: item.value })) : ('-') }, item.label) })] }, item.label));
        }) }));
};
export default React.memo(SeniorShortDetail);
