var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isEmpty } from 'lodash';
import { showApplicationLoader, hideApplicationLoader, } from '../../../../../common/ApplicationLoader/ApplicationLoader.action';
import { getCareAgentService } from 'services/careAgentAccountService/careAgentAccount.service';
import { getCurrentSenior, unmaskPhoneNumber } from 'globals/global.functions';
import { DIALOG_TYPES } from 'globals/global.constants';
import { addAgentService, disableAgentService, resendOtpService, } from 'services/adminServices/agentAccount.service';
import { closeDialog, getCareAgentList, getCareAgentListSuccess, openOverlayDialog, resetCareAgentList, showError, } from 'store/commonReducer/common.action';
// eslint-disable-next-line max-len
import { validateEmailAddressService } from 'services/addUserService/validateEmailAddressAndMobileService';
import { Roles } from 'globals/enums';
export var GET_CARE_AGENTS = 'GET_CARE_AGENTS';
export var RESET_CARE_AGENTS = 'RESET_CARE_AGENTS';
export var RESET_PAGINATION = 'RESET_PAGINATION';
export var END_PAGINATION = 'END_PAGINATION';
// TODO: will move getCareAgents to a separate commmon reducer outside Admin.
/**
 * @description action creator to get the data of all care agents.
 * @param {string} lastEvaluatedKey
 * @returns void
 */
export var getCareAgents = function (lastEvaluatedKey, careAgentIds) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    params = {
                        last_evaluated_key: lastEvaluatedKey,
                    };
                    if (careAgentIds === null || careAgentIds === void 0 ? void 0 : careAgentIds.length) {
                        params = {
                            last_evaluated_key: lastEvaluatedKey,
                            careagent_ids: JSON.stringify(careAgentIds),
                        };
                    }
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, getCareAgentService(params)];
                case 1:
                    response = _a.sent();
                    if (isEmpty(response.result)) {
                        dispatch({ type: END_PAGINATION });
                    }
                    dispatch({
                        type: GET_CARE_AGENTS,
                        payload: response.result,
                    });
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, response];
                case 2:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_1));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @functions addEditAgent
 * @description action creator to add a new agent with edited details.
 * @param {*} data
 */
export var addEditAgent = function (data, existingEmail, triggerOtp) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        employee_id: data.empId,
                        name: { first_name: data.firstName, last_name: data.lastName },
                        email: data.email.toLowerCase(),
                        access: data.access,
                        phone_number: {
                            number: unmaskPhoneNumber(data.phone),
                            ext: data.extension || '',
                        },
                        location: {
                            zipcode: data.zipCode,
                            city: data.city,
                            state: data.state,
                        },
                        shift: data.access === Roles.BDM ? null : data.agentShift,
                        is_active: true,
                    };
                    if (data.userId) {
                        params.user_id = data.userId;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, addAgentService(params)];
                case 2:
                    _a.sent();
                    if (!(triggerOtp && existingEmail !== data.email)) return [3 /*break*/, 4];
                    return [4 /*yield*/, dispatch(resendOtp(data.email, false))];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    //Closing the dialog here as it was appearing in login page while changing role of self
                    dispatch(closeDialog());
                    dispatch(resetCareAgentList());
                    return [4 /*yield*/, dispatch(getCareAgentList())];
                case 5:
                    response = _a.sent();
                    dispatch(getCareAgentListSuccess(response));
                    dispatch(openOverlayDialog({
                        type: DIALOG_TYPES.SUCCESS,
                        firstMessage: "User ".concat(data.firstName, " ").concat(data.lastName, " ").concat(data.userId ? 'updated' : 'added', " successfully."),
                    }));
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_2));
                    return [2 /*return*/, error_2];
                case 7: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @functions disableAgent
 * @description action creator to disable care agent account.
 * @param {*} data
 */
export var disableAgent = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var params, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    employee_id: data.employeeId,
                    email: data.email,
                    is_active: false,
                    user_id: data.userId,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                dispatch(showApplicationLoader());
                return [4 /*yield*/, disableAgentService(params)];
            case 2:
                _a.sent();
                dispatch(resetCareAgentList());
                return [4 /*yield*/, dispatch(getCareAgentList())];
            case 3:
                response = _a.sent();
                dispatch(getCareAgentListSuccess(response));
                dispatch(openOverlayDialog({
                    type: DIALOG_TYPES.SUCCESS,
                    firstMessage: "User ".concat(data.name.firstName, " ").concat(data.name.lastName, " disabled successfully."),
                }));
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 5];
            case 4:
                error_3 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_3));
                return [2 /*return*/, error_3];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
export var resendOtp = function (email, showSuccessMessage, withAccountId) {
    if (showSuccessMessage === void 0) { showSuccessMessage = true; }
    if (withAccountId === void 0) { withAccountId = false; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, accountID, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        email: email,
                    };
                    if (withAccountId) {
                        accountID = getCurrentSenior().accountID;
                        params.account = accountID;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, resendOtpService(params)];
                case 2:
                    _a.sent();
                    dispatch(hideApplicationLoader());
                    if (showSuccessMessage) {
                        dispatch(openOverlayDialog({
                            type: DIALOG_TYPES.SUCCESS,
                            firstMessage: "OTP is successfully sent to email ".concat(email, "."),
                        }));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_4));
                    return [2 /*return*/, error_4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var isFirstLogin = function (email) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var param, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                param = {
                    email: email,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(showApplicationLoader());
                return [4 /*yield*/, validateEmailAddressService(param)];
            case 2:
                response = _a.sent();
                dispatch(hideApplicationLoader());
                return [2 /*return*/, response.data];
            case 3:
                error_5 = _a.sent();
                console.error(error_5);
                dispatch(hideApplicationLoader());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
