var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_CURRENT_STATE, SET_START_END_TIME, RE_RENDER_GRAPH, WELLNESS_DATA, } from './WellnessDashboard.types';
import { RESET_DATES_WELLNESS_DASHBOARD, SET_SELECTED_DATE, SET_SLEEP_START_END_TIME, } from './WellnessDashboard.actions';
import moment from 'moment';
var INITIAL_STATE = {
    currentState: 'day',
    startTime: '',
    endTime: '',
    reRender: false,
    sleepStartTime: null,
    sleepEndTime: null,
    selectedDate: {
        day: moment(),
        week: moment().startOf('week'),
        month: moment(),
    },
};
export var wellnessDashboardReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case SET_CURRENT_STATE:
            return __assign(__assign({}, state), { currentState: action.currentState });
        case WELLNESS_DATA:
            return __assign(__assign({}, state), action === null || action === void 0 ? void 0 : action.payload);
        case SET_START_END_TIME:
            return __assign(__assign({}, state), { startTime: action.startTime, endTime: action.endTime });
        case RE_RENDER_GRAPH:
            return __assign(__assign({}, state), { reRender: action.value });
        case SET_SLEEP_START_END_TIME:
            return __assign(__assign({}, state), { sleepStartTime: action.payload.startTime, sleepEndTime: action.payload.endTime });
        case SET_SELECTED_DATE: {
            return __assign(__assign({}, state), { selectedDate: __assign(__assign({}, state.selectedDate), (_a = {}, _a[action.payload.type] = action.payload.date, _a)) });
        }
        case RESET_DATES_WELLNESS_DASHBOARD: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
};
