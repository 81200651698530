var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CLOSE_TIMEOUT_MODEL, FETCHING_LOGIN_DETAILS, FETCHING_LOGIN_DETAILS_DONE, LOGOUT_USER, OPEN_TIMEOUT_MODEL, USER_LOGGED_IN, } from './Login.types';
import { UPDATE_PASSWORD_EXPIRY_WARNING } from './Login.action';
var INITIAL_STATE = {
    username: '',
    isAuthenticated: false,
    loading: false,
    sessionInterval: null,
    isTimeoutModel: false,
    userRole: [],
    roleConfig: {
        defaultPage: "/login",
        defaultHomeRoute: '/login',
        accessLabel: '',
        isPusher: false,
        careInsightPrivileges: {
            isAlerts: false,
            isApproveAlert: false,
            isApproveSummary: false,
            isAlertActionNotification: false,
            isSummaryActionNotification: false,
            isAlarms: false,
            isCIRangeMilestoneNotification: false,
            isLocationData: false,
        },
    },
    userName: {
        first_name: '',
        last_name: '',
        middle_name: '',
    },
    passwordCreatedData: {
        createdDate: '',
        isPasswordResetWarning: false,
        passwordExpiryDate: '',
    },
    facilityDetails: {
        facilityId: '',
        facilityType: '',
        facilityName: '',
    }
};
export var loginReducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case FETCHING_LOGIN_DETAILS:
            return __assign(__assign({}, state), { loading: true });
        case FETCHING_LOGIN_DETAILS_DONE:
            return __assign(__assign(__assign({}, state), { loading: false }), action.payload);
        case 'SET_SESSION_INTERVAL':
            return __assign(__assign({}, state), action.payload);
        case LOGOUT_USER:
            localStorage.removeItem('userInfo');
            localStorage.removeItem('seniorInfo');
            return INITIAL_STATE;
        case OPEN_TIMEOUT_MODEL:
            return __assign(__assign({}, state), { isTimeoutModel: true });
        case CLOSE_TIMEOUT_MODEL:
            return __assign(__assign({}, state), { isTimeoutModel: false });
        case USER_LOGGED_IN: {
            return __assign(__assign(__assign({}, state), { isAuthenticated: true }), action.payload);
        }
        case UPDATE_PASSWORD_EXPIRY_WARNING: {
            return __assign(__assign({}, state), { passwordCreatedData: __assign(__assign({}, state.passwordCreatedData), { isPasswordResetWarning: action.payload.isPasswordResetWarning, passwordExpiryDate: action.payload.passwordExpiryDate }) });
        }
        default:
            return state;
    }
};
