var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { API } from 'globals/api';
import { GOALS_END_POINTS } from 'globals/apiEndPoints';
import GoalsDataParser from './parser/goalsDataParser';
/**
 * @description API service to get the goals data and parse the data
 * @function getGoalsService
 * @param {IGetGoalParams} params of get goals api
 * @returns {IGetGoalsService}
 */
export function getGoalsService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var goalsDataResponse, dataParser, goalsRowsData, lastEvaluatedKey, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: GOALS_END_POINTS.GET_GOALS_DATA,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    goalsDataResponse = _c.sent();
                    dataParser = new GoalsDataParser();
                    goalsRowsData = dataParser.parseGoalData((_a = goalsDataResponse === null || goalsDataResponse === void 0 ? void 0 : goalsDataResponse.data) === null || _a === void 0 ? void 0 : _a.goal_action_data);
                    lastEvaluatedKey = (_b = goalsDataResponse === null || goalsDataResponse === void 0 ? void 0 : goalsDataResponse.data) === null || _b === void 0 ? void 0 : _b.last_evaluated_key;
                    return [2 /*return*/, { data: goalsRowsData, lastEvaluatedKey: lastEvaluatedKey }];
                case 2:
                    error_1 = _c.sent();
                    console.log(error_1);
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getGoalActionMapService(params) {
    return __awaiter(this, void 0, Promise, function () {
        var response, dataParser, parsedData, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API({
                            url: GOALS_END_POINTS.GET_GOALS_ACTION_MAP_DATA,
                            method: 'get',
                            params: params,
                        })];
                case 1:
                    response = _b.sent();
                    dataParser = new GoalsDataParser();
                    parsedData = dataParser.parseGoalActionMapData((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.goal_action_mapping);
                    return [2 /*return*/, parsedData];
                case 2:
                    error_2 = _b.sent();
                    console.log(error_2);
                    throw error_2;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function postGoalsService(params) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, API({
                        url: GOALS_END_POINTS.POST_GOALS_DATA,
                        method: 'post',
                        data: params,
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
