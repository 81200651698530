var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_GOALS_SUCCESS, UPDATE_GOALS_PAGE_NUMBER, GET_GOAL_ACTION_MAP, UPDATE_GOAL_ACTION_MAP, GET_GOALS, GET_GOALS_FAILS, RESET_GOALS, } from './goals.action';
import { intialStateGoals, } from './goals.types';
var goalsReducer = function (state, action) {
    if (state === void 0) { state = intialStateGoals; }
    switch (action.type) {
        case GET_GOALS: {
            return __assign(__assign({}, state), { loading: true });
        }
        case GET_GOALS_SUCCESS:
            return __assign(__assign({}, state), { goalsRowsData: action.payload.data, lastEvaluatedKey: action.payload.lastEvaluatedKey, loading: false });
        case GET_GOALS_FAILS:
            return __assign(__assign({}, state), { loading: false });
        case UPDATE_GOALS_PAGE_NUMBER: {
            return __assign(__assign({}, state), { currentPage: action.payload });
        }
        case GET_GOAL_ACTION_MAP: {
            return __assign(__assign({}, state), { goalsActionMap: __assign({}, action.payload.response) });
        }
        case UPDATE_GOAL_ACTION_MAP: {
            return __assign(__assign({}, state), { goalsActionMap: __assign({}, action.payload.goalActionMap) });
        }
        case RESET_GOALS: {
            return intialStateGoals;
        }
        default:
            return state;
    }
};
export default goalsReducer;
